._7ZOsUa_container {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}

._2wqk6G_link {
  margin-top: var(--s-m);
  font-size: var(--f-m);
  font-style: bold;
  color: var(--c-secondary);
  display: inline-flex;
}

._2wqk6G_link span {
  margin-right: 4px;
}

._2wqk6G_link:hover {
  filter: brightness(.8);
}

._2wqk6G_leviating {
  color: var(--c-primary);
  margin-top: -2px;
  font-size: 20px;
  animation: 2s cubic-bezier(.17, .67, .83, .67) infinite _2wqk6G_float;
}

._2wqk6G_primary {
  color: var(--c-primary);
}

@keyframes _2wqk6G_float {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.BOBUQq_footer {
  margin-top: var(--s-xl);
  background-color: var(--footer-color);
  border-top: 1px solid var(--c-text);
  padding-top: 12px;
  padding-bottom: 24px;
}

.BOBUQq_item + .BOBUQq_item {
  margin-left: 20px;
}

.BOBUQq_items {
  width: 100%;
  display: flex;
}

@media (width >= 400px) {
  .BOBUQq_items {
    width: auto;
  }
}

@media (width >= 600px) {
  .BOBUQq_code {
    display: inline-block !important;
  }
}

.xU17Sa_burger {
  color: #000;
  cursor: pointer;
  z-index: var(--z-highest);
  top: var(--page-padding);
  right: var(--page-padding);
  margin: 6px -10px -10px;
  padding: 0 10px 10px 18px;
  font-weight: bold;
  position: fixed;
}

@media (width >= 700px) {
  .xU17Sa_burger, .xU17Sa_container {
    display: none;
  }
}

.xU17Sa_active .xU17Sa_drawer {
  opacity: 1;
}

.xU17Sa_drawer {
  color: #000;
  z-index: var(--z-highest);
  opacity: 0;
  background-color: #fff;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  transition: opacity .225s cubic-bezier(0, 0, .2, 1) 2s;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.xU17Sa_drawerMenu {
  text-align: center;
  font-size: var(--f-l);
  width: 100%;
  font-family: Charter;
}

.xU17Sa_drawerMenuItem + .xU17Sa_drawerMenuItem {
  margin-top: 38px;
}

.xU17Sa_closeIcon {
  font-size: var(--f-xl);
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  top: 27px;
  right: 28px;
}

.xU17Sa_backgroundBlur {
  -webkit-tap-highlight-color: transparent;
  z-index: var(--z-lowest);
  background-color: #000000b3;
  position: fixed;
  inset: 0;
}

.MqZHCq_header {
  padding-top: 42vh;
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
  color: var(--c-text-secondary);
  justify-content: space-between;
  display: flex;
}

@media (width >= 700px) {
  .MqZHCq_header {
    padding-top: 24px;
  }
}

.MqZHCq_claim {
  letter-spacing: -.003em;
  word-break: break-word;
  color: var(--c-text);
  font-family: Bagnard;
  font-size: var(--f-xxxl);
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.077;
}

@media (width >= 700px) {
  .MqZHCq_nav {
    display: block;
  }
}

.MqZHCq_navItem {
  font-size: var(--f-m);
  filter: grayscale() blur(.7px);
}

.MqZHCq_navItem + .MqZHCq_navItem {
  margin-left: 20px;
}

.MqZHCq_activeNavItem {
  color: #000;
  filter: grayscale(0);
  display: inline-flex;
}

.xW6Hiq_logo {
  letter-spacing: -.003em;
  word-break: break-word;
  color: var(--c-text);
  font-family: Bagnard;
  font-size: var(--f-xxxl);
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.077;
}

.ZMO6Uq_wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  inset: 0;
}

@keyframes ZMO6Uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.ZMO6Uq_emoji {
  filter: grayscale(.5);
  font-size: var(--f-xl);
  animation: 2.33s cubic-bezier(.21, .51, .83, .67) infinite ZMO6Uq_rotating;
}
/*# sourceMappingURL=index.fc63e6be.css.map */
